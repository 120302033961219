.minzi-tree {
    width: 100%;
}

.minzi-tree ul {
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;
}

.minzi-tree ul li {}

.minzi-item-title {
    overflow: hidden;
    white-space: nowrap;
    max-width: 250px;
}

.minzi-item-menu:hover {
    background-color: var(--minzi-dark-card-color, #ebeaea);
}

.minzi-item-menu-active {
    background-color: var(--minzi-dark-card-color, #ebeaea);
}

#document-editor-create {
    margin-top: 20px;
    border-left: 3px solid var(--minzi-dark-card-color, #ebe9f1) !important;
}

#document-editor-card {
    margin-top: 20px;
}

.minzi-item-menu {
    padding: 3px;
    border-radius: 4px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.minzi-item-menu div {
    padding-left: 5px;
}

.minzi-item-menu div small {
    padding-left: 3px;
    padding-top: 3px;
}

.react-split__sash--vertical {
    background-color: var(--minzi-dark-card-color, #eceaea);
}

.minzi-tree-actions-button {
    display: none !important;
}

.minzi-tree-actions:hover .minzi-tree-actions-button {
    display: block !important;
}

.minzi-tree-actions:hover .minzi-item-title {
    overflow: hidden;
    white-space: nowrap;
    max-width: 180px;
}

.minzi-tree-actions-button-invert {
    display: block;
    margin-right: 3px;
}

.minzi-tree-actions:hover .minzi-tree-actions-button-invert {
    display: none;
}

.button-circle {
    border-radius: 25px;
}

.text-editable div {
    border-radius: 0px;
    border: none;
    background: transparent;
}

.text-editable input {
    font-size: 40px;
    font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: bold;
    padding: 0px;
    margin: 0px;
    height: 50px !important;
}

.button-tooltip span {
    color: #fff;
}

.ql-editor-document .ql-editor {
    padding: 0px;
}

.text-editable-small div {
    border-radius: 0px;
    border: none;
    background: transparent;
}

.text-editable-small input {
    font-size: 20px;
    font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: normal;
    padding: 0px;
    margin: 0px;
    height: 30px !important;
}


[contenteditable] {
    -webkit-tap-highlight-color: transparent;
}

.editable-input {
    font-size: 40px;
    font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
    padding: 0xp;
}

.minzi-editor-frame-div {
    padding: 3rem;
}

.minzi-document {
    height: 100%;
}

.minzi-editor-frame {
    border: none !important;
    width: 100%;
    height: 100%;
}

.minzi-document-header {
    /* background-color: #ebeaea;
    width: 100%;
    height: 40px; */
    margin-bottom: 0px;
    /* border-radius: 5px; */
}

.minzi-document-body {
    height: 80vh;
}

.minzi-document-info {
    display: flex;
    align-items: center;
    justify-content: center;
}

.minzi-document-info .documento-info {
    margin-right: 15px;
}

[data-testid='modal-history--header'],
[data-testid='modal-history--body'] {
    padding: 0px;
}

.separator-vertial {
    width: 1px;
    height: 100%;
    border-right: 2px solid #eceaea
}

.modal-history-menu-right {
    width: 320px;
}

.modal-history-header {
    padding-top: 1rem;
    padding-left: 1rem;

    margin-bottom: 1rem;
}

.modal-history-body {
    width: 100%;
}

.modal-history-footer {
    padding-left: 1rem;
    padding-right: 1rem;
}

.modal-history-item {
    margin-left: .5rem;
    margin-right: .5rem;
    padding: .5rem;
    cursor: pointer;
}

.modal-history-item-active {
    background-color: var(--minzi-dark-card-color, #ebeaea);
    border-radius: 5px;
}

.d-flex-center {
    display: flex;
    align-items: center;
}

.d-justify-content {
    justify-content: space-between;
}

.separator-frame {
    border-right: 2px solid var(--minzi-dark-card-color, #ebeaea) !important;
}

.document-type-options .vx-radio-primary {
    margin-right: 10px;
    margin-bottom: 20px;
}

.buttons-export button {
    margin-bottom: 10px;
}

.permission-person {
    display: flex;
    align-items: center;
}

.permission-person-right {
    display: flex;
    align-items: center;
    float: right;
}

.permission-share {
    width: 400px;
}

.permission-person-right .vx-checkbox-primary {
    margin-right: 8px;
}

.permission-person-children {
    margin-left: 90px;
}

.minzi-elements {
    overflow: auto;
    height: 81vh;
}

.table-permission {
    width: 100%;
    margin-top: 10px;
}

.table-permission th td {}

.table-permission-body {
    height: 500px;
    max-height: 500px;
    min-height: 500px;
}

.table-permission tr td {
    border-bottom: 1px solid var(--minzi-dark-card-color, #ebeaea);
    padding-bottom: 5px;
    padding-top: 5px;
}

.btn-permission-change {
    margin-right: 5px;
}

.btn-permission-active {
    border-bottom: 2px solid rgb(0, 122, 255);
}

.select-permission-group {
    margin-top: 8px;
    width: 100%;
    margin-bottom: 10px;
}

.row-person-name {
    width: 320px;
}