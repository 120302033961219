.text-white {
    color: white !important;
}

.btn-create-card {
    border-radius: 5px;
    height: 40px !important;
}

.btn-create-card span {
    height: 40px !important;
    padding-top: 4px;
}

.btn-create {
    margin-left: 1rem;
}

.atl-button {
    border-radius: 4px;
}

body.dark-layout .atl-button.primary span {
    color: #282E33 !important;
    font-weight: bold !important;
}

#create-card-projects {
    width: 100%;
}

.single-select {
    width: 350px;
    min-width: 150px;
}


.single-select-filter-epic {
    width: 150px;
    min-width: 150px;
}

.create-card-data {
    width: 350px;
    min-width: 150px;
}

.ql-toolbar {
    border: 2px solid #DFE1E6 !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.ql-container {
    border-right: 2px solid #DFE1E6 !important;
    border-left: 2px solid #DFE1E6 !important;
    border-bottom: 2px solid #DFE1E6 !important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.person-select-name {
    padding-top: 5px;
    margin-left: 5px;
}

.create-card-title {
    font-size: 20px;
    padding-top: 5px;
}

.notifications-suricatoo {
    cursor: pointer;
    margin-right: 0.5rem;
}

.notifications-suricatoo:hover {
    color: #0052CC;
}

.position-notification {
    top: -6px !important;
    right: -5px !important
}

.notification-line {}

.notification-items {
    max-height: 300px;
    overflow: auto;
}

.notification-items hr {
    margin-top: 3px;
    margin-bottom: 3px;
}

.notification-avatar {
    margin-right: 1rem;
}

.notification-name {
    margin-right: 5px;
    margin-bottom: 5px;
}

.notification-description {
    margin-bottom: 5px;
}

.notifications-suricatoo .dropdown-menu {
    top: 11px !important;
    left: 9px !important;
}

.notification-card {
    font-weight: bold;
    margin-left: 1rem;
}

.header-notification {
    text-align: center;
    width: 100%;
    padding: 1rem;
}

.notification-body {
    min-width: 350px
}


:root body.dark-layout {
    --ds-border-input: #A6C5E229 !important;
    --ds-text-inverse: #FFFFFF !important;
    --minzi-content-dark-bg: #282E33;
    --minzi-dark-card-color: #1D2125;
    --minzi-grid-dark-color: #282E33;
    --minzi-theme-dark-heading-color: #ebeefd;
    --minzi-theme-dark-text-color: #FFFFFF;
    --minzi-theme-dark-table-bg: #1D2125;
    --minzi-ex-col-border-color: #808080;
    --minzi-dark-chat-bg-color: #1D2125;
    --minzi-theme-dark-border-color: #7d96ad29;
    --minzi-hilight-color: #FFC400;
    --minzi-badge: #0055CC;
    --ds-UNSAFE_util-transparent: transparent;
}


.btn-inProgress {
    color: #0747A6 !important;
    background-color: var(--minzi-badge, #DEEBFF) !important;
}

.btn-inProgress:hover {
    color: #0747A6 !important;
}

.btn-done span {
    color: #006644 !important;
    background-color: #E3FCEF !important;
}

.btn-done:hover {
    color: #006644 !important;
    background-color: #E3FCEF !important;
}


body.dark-layout .react-loading-skeleton {
    --base-color: #282E33 !important;
    --highlight-color: #1D2125 !important;
}

:root body.light-layout {
    --ds-border-input: #DFE1E6 !important
}

.card-description {
    color: var(--minzi-theme-dark-text-color, #636363) !important;
}

body.dark-layout .bg-white {
    background-color: var(--minzi-content-dark-bg) !important;
}

[data-testid='minzi-tags-select--content'] div {
    /*max-height: 250px !important;*/
}

[data-testid='badge-filter-options'] {
    margin-left: 3px;
}

.menu-size .dropdown-menu {
    width: 250px !important;
}

[data-testid='minzi-badge-primary'] {
    background: var(--minzi-badge, #0055CC) !important;
}

body.dark-layout a {
    color: #94bfff;
}

body.dark-layout a:hover {
    color: #4383e2 !important;
}

body.dark-layout .light {
    color: var(--minzi-theme-dark-heading-color) !important;
}

.priority-0,
.priority-very-high {
    background-color: red;
    color: white !important;
    border-radius: 5px;
    font-weight: bold;
    font-size: 12px;
    padding: 3px;
}

.priority {
    min-width: 15px;
    min-height: 15px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 12px;
    padding: 3px;
}

.priority-1,
.priority-high {
    background-color: orange;
    color: black !important;

}

.priority-2,
.priority-medium {
    background-color: yellow;
    color: black !important;
}

.priority-3,
.priority-low {
    background-color: greenyellow;
    color: black !important;
}

.priority-4,
.priority-very-low {
    background-color: green;
    color: white !important;
}

.priority-undefined,
.priority-9,
.priority-no {
    background-color: gray;
    color: white !important;
}

.priority-group {
    margin-left: 5px;
}

.without-of-calendar {
    background: var(--minzi-dark-card-color, rgb(235, 236, 240));
    border-radius: 5px;
    padding-bottom: 10px;

}

.without-of-calendar h3 {
    padding-top: 10px;
    padding-left: 10px;
}

.without-of-calendar-card {
    background: var(--minzi-content-dark-bg, #fff);
    border: 1px solid var(--minzi-theme-dark-border-color, #ebeefd);
    padding: 5px;
    border-radius: 3px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 2px;

}

.without-of-calendar-card .without-of-calendar-card-actions {
    display: none;
}

.without-of-calendar-card:hover .without-of-calendar-card-actions {
    display: block;
}

.without-of-calendar-card-actions {
}

.without-of-calendar-cardKey {
    font-weight: bold;
    width: 120px;
}

.without-of-calendar-cards {
    min-height: 72vh;
    max-height: 72vh;
    overflow: auto;
}

.without-of-calendar-card span {
    max-width: fit-content;
}

.without-of-calendar-card small {
    margin-left: 4px;
}

.minzi-date-picker {
    width: 120px;
    margin-right: 10px;
}

.minzi-icon-center {
    padding-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
}