.single-select{
    max-width: 350px;
    width: 350px;
}

.single-select-sprint{
    max-width: 388px;
    width: 388px;
}

.flag-issue div {
    box-shadow: none !important;
}

.textfield-card-name {
    border: 2px solid #DFE1E6 !important;
    border-radius: 3px;
    background-color: var(--ds-background-input, #FAFBFC);
    font-size: 14px !important;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Fira Sans','Droid Sans','Helvetica Neue',sans-serif !important;
}

.MuiOutlinedInput-root {
    padding: 0px !important;
}