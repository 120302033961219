.import-table tr td {
    background-color: var(--minzi-content-dark-bg, #fff);
    border: 0.2px solid var(--ds-border-input, #ddd);
    height: 47.89px;
    padding: 5px;
}

.import-table tr th {
    border: 0.2px solid var(--ds-border-input, #ddd);
    height: 47.89px;
    padding: 5px;
}

.td-warning {
    background-color: rgb(255, 199, 182) !important;
    color: var(--minzi-dark-card-color);
    font-weight: bold;
}

.drop_area {
    min-width: 100% !important;
    max-width: 100% !important;
    padding: 6.9rem !important;
    text-align: center;
    border: 2px dashed #DEEBFF;
    cursor: pointer;
}

.drop-file-area {
    font-size: 12px;
    font-weight: bold;
}

.import-table-scroll {}

.button-action-import {
    width: 80px;
}