.content-rules2{
    height: 300px;
    width: 100%;
}

.labels-checkbox-bottom {
    margin-bottom: 8px;
}

ul.days-header {
    padding: 0;
    margin: 0;
    border-radius: 10px;
    list-style: none;
    display: flex;
    border: solid 1px #0052CC;
    justify-content: space-between;
    border-radius: 5px;
}

ul.days-header li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: solid 1px #0052CC;
    width: 100%;
    cursor: pointer;
    padding: 8px 3px;
    color: #0052CC;
}

ul.days-header li > span {
    margin-top: -2px;    
}

ul.days-header li:hover {
    background: rgba(53,45,151, .1);
}

ul.days-header li.selected {
    background: #0052CC;
    color: white
}


ul.days-header li:last-child {
    border-right: none;
    cursor: initial;
}

input[type="time"].modal-edit-time::-webkit-calendar-picker-indicator {
    display: none;
}
.task-time {

}
.modal-edit-times .task-time button {
    border: 0;
    background-color: transparent;
    height: 45px;
    color: #1f9d57;

}
.task-time button:active {
    outline: none;
}
.task-time button:focus {
    outline: none;
}
.time-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 4px;
    grid-auto-rows: 55px;
    text-align: center;
    height: 250px;
    overflow: auto;
}
.time-list .times {
    display: grid;
    grid-template-columns: 1fr 1fr 30px 30px;
    grid-auto-rows: 45px;
    gap: 4px;
    text-align: center;
}
.time-list button {
    border: 0;
    background-color: transparent;
}
.time-list button:active {
    outline: none;
}
.time-list button:focus {
    outline: none;
}
.time-list button.add {
    color: #1f9d57
}
.time-list button.remove {
    color: #e42728
}


.time-list input[type="time"]::-webkit-calendar-picker-indicator {
    display: none; 
    text-align: center;
}
/* .time-modal-size {
    min-width: 765px
}

@media (max-width: 766px) {
    .time-modal-size {
        min-width: 95%
    }
    
} */
.bd-l {
    border-left: 1px solid #f2f2f2;
}
.btn-nopadding{
    padding: 0px !important
}

.btn-done {
    padding-left: 6px !important
}

.modal-edit-times {
    position: absolute;
    z-index: 9;
    height: 100%;
    width: 100%;
    background:var(--minzi-content-dark-bg, rgba(255,255,255,1));
    left: 0;
    transform: scale(.5);
    opacity: 0;
    visibility: hidden;
    transition: all 240ms ease;
}

.modal-edit-times.show {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
}

.modal-edit-times .edit-times-header {
    padding: 7px 15px;
    border-bottom: solid 1px #F4F4F4;
}

.modal-edit-times .edit-times-header h6 {
    margin: 0;
}

.modal-edit-times .time-edit-rows {
    padding: 15px 15px 0 15px;
}

.modal-edit-times .time-edit-rows .button-disabled {
    color:rgba(53,45,151, .1)
}

.modal-edit-times .time-edit-rows .total-line-time {
    width: 40px;
    font-size: 26px;
    text-align: right;
    display: table;
    margin-top: 3px;
}

.modal-edit-times .time-edit-rows .time-row {
    padding: 5px 5px 5px 0;
}

.modal-edit-times  table tr:nth-child(odd) {
    background: rgba(100,100,100,.08);
}

.modal-edit-times  .edit-type {
    width: 3px;
    height: 40px;
    position: relative;
    margin-right: 5px;
}

.modal-edit-times .add-initial-time {
    color: #0052CC;
    background: rgba(100,100,100,.08);
    padding: 5px;
    font-size: 13px;
    text-align: center;
    margin-bottom: 5px;
}

.modal-edit-times .edit-times-footer {
    padding: 7px 15px;
    border-top: solid 1px #F4F4F4;
}

.modal-edit-times .edit-times-footer b{
    font-size: 28px
}

.modal-edit-times .edit-times-footer b.gray{
    font-size: 22px;
    padding-right: 7px
}


.react-select.is-invalid .select__control {
    border-color: #ea5455;
}

.edit-time-task-running {
    background-color: #28c76f;
    padding: 3px 8px;
    color: #F4F4F4;
    border-radius: 5px;
}


.error-message {
    background-color: #ea5455;
    position: absolute;
    width: 100%;
    padding: 5px;
    text-align: center;
    color: white;
    opacity: .95;
}


/* .hora-button {
    font-size: 1rem;
    margin-top: 3px;
}

.days-header div button {
    padding-left: 2rem;
    padding-right: 4rem;
}
.icon-pointer {
    cursor: pointer;
}

.btn-clock{
    padding-left: 1rem;
    padding-right: 1rem;
}

.badge-restante {
    color: #7367f0!important;
    background-color: #dedbfb;
    font-weight: bold;
}

.btn-nopadding{
    padding: 0px !important
}

.btn-done {
    padding-left: 6px !important
}

.btn-ok {
    padding: 0.8rem 1.6rem !important;
    margin-left: 3px;
}

.texto-sublinhado {
    text-decoration: line-through;
}

.descricao-tarefa {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.badge-planejado{
    font-size: 13px;
    color: black;
    background-color: #e9e9e9;
    margin-top: 2px;
}

.badge-faltante{
    font-size: 13px;
    color: #7367f0!important;
    background-color: #dedbfb;
    cursor: pointer;
}

.btn-starStop-border {
    border-left: 1px solid #7367f0;
    border-top: 1px solid #7367f0;
    border-bottom: 1px solid #7367f0;
    border-right: none;
}

.btn-finalizado-border {
    border-left: none;
    border-top: 1px solid #7367f0;
    border-bottom: 1px solid #7367f0;
    border-right: 1px solid #7367f0;
}

.time-label {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;

}

input[type="time"]::-webkit-calendar-picker-indicator {
    display:none;
} */

.download-suricatoo {
    background: white;
    padding: 5px 10px;
    border-radius: 10px;
    border: solid 2px #0052CC;
    color: #0052CC;
    transition: all 240ms ease-in-out;
}

.download-suricatoo svg path {
    fill: #0052CC;
}
.download-suricatoo .so-texts-title {
    font-weight: 700;
}

.download-suricatoo .so-texts-name {
    font-size: 20px;
    margin-top: -5px;
}

.download-suricatoo:hover{
    background: #0052CC;
    color: white;
}

.download-suricatoo:hover svg path {
    fill: white;
}

.timer-custom-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #0052CC;
    color: #0052CC;
    border-radius: 5px;
    position: relative;
}
.timer-custom-button .timer-custom-button-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 10px;
    width: 130px;
    cursor: pointer;

}

.timer-custom-button.finished {
    border: solid 1px #DCDCDC;
    background: #F1F1F1;
    color: #a7a7a7;
}
.timer-custom-button.finished .timer-custom-button-cta {
    width: 90px;
    cursor: not-allowed;
}

.timer-custom-button.timer-checkbox-right .timer-custom-button-cta {
    width: 108px;

}
.timer-custom-button.timer-checkbox-right {
    padding-right: 22px;

}
.timer-custom-button .timer-custom-button-icon {
    width: 30px;
    height: 30px;
    margin-right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.timer-custom-button .timer-custom-button-status {
    display: flex;
    align-items: center;
    justify-content: center;
}

.timer-custom-button .timer-custom-button-check {
    width: 18px;
    height: 18px;
    /*background: rgba(255,255,255,.8);*/
    border: solid 2px rgba(115,103,240,.7);
    position: absolute;
    top: 3px;
    right: 3px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.timer-custom-button .timer-custom-button-check.disabled {
    border: solid 2px rgba(115,103,240,.35);
    color: white;
    cursor: not-allowed;
}

.timer-custom-button .timer-custom-button-check.checked {
    background: #28c76f;
    border-color: #28c76f;
    color: white;
}


.timer-custom-button.timer-running {
    background: #0052CC !important;
    color: white;
}
.timer-custom-button.timer-running .timer-custom-button-check {
    border: solid 2px rgba(255,255,255,.7);
}

.timer-custom-button.timer-disabled {
    opacity: .8;
    cursor: not-allowed;
}
.custom-buttom-loading {
    position: absolute;
    background: #0052CC;
    width: 100%;
    height: 100%;
    left: 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
}

.time-page-sidebar {
    width: 350px;
    max-width: 100%;
}

.time-page-content {
    margin-left: 30px;
}

.time-row {
    display: flex;
}

@media (max-width: 500px) {
    .time-row {
        display: flex;
        flex-direction: column;
    }
    .descricao-tarefa {
        margin-bottom: 15px;
    }
    .time-functions {
        justify-content: space-between;
    }
    .time-timers {
        flex-grow: 1;
        justify-content: space-between;
    }
    .days-header {
        font-size: 12px;
    }
    .time-page-content h3 {
        font-size: 17px;
    }
    .gantt-header {
        flex-direction: column;
    }
    .gantt-idle {
        justify-content: flex-start !important;
        align-items: flex-start !important;
        text-align: left !important;
        margin-top: 10px;

    }
}


/*.is-disconnected {
    background: #ff9f43;
    color: white;
    position: absolute;
    width: 100%;
    left: 0;
    top: -2px;
    padding: 8px;
    z-index: 9;
    text-align: center;
}*/