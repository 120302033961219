.mz-text-input {
    background-color: transparent;
    border: 0;
    box-sizing: border-box;
    color: inherit;
    cursor: inherit;
    font-size: 14px;
    min-width: 0;
    outline: none;
    width: 100%;
    line-height: 1.4285714285714286;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.mz-text-input:not([data-compact]) {
    padding: 8px 6px;
    height: 2.57em;
}


.dv-text-input:focus-within {
    background-color: var(--ds-background-input-pressed, #FFFFFF) !important;
    border-color: var(--ds-border-focused, #4C9AFF) !important;
}

.dv-text-input:hover {
    background-color: var(--ds-background-input-hovered, #EBECF0);
}

.dv-text-input {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--ds-background-input, #FAFBFC);
    border-color: var(--ds-border, #DFE1E6);
    color: var(--ds-text, #091E42);
    cursor: text;
    border-radius: 3px;
    border-width: 2px;
    border-style: solid;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    font-size: 14px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    max-width: 100%;
    overflow: hidden;
    -webkit-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    word-wrap: break-word;
    vertical-align: top;
    pointer-events: auto;
}
